import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';

import dom from '../../../wrapper/DomWrapper';

const errorHandler = (selector, onerror) => {
  const elImages = dom.getCollection(selector);

  if (isEmpty(elImages) || !isFunction(onerror)) return;

  elImages.forEach((el) => {
    const elImage = dom.getElement('img', el);

    if (!elImage) return;

    elImage.onerror = () => onerror(elImage, el);
  });
};

export default errorHandler;
