import dom from '../wrapper/DomWrapper';

/**
 * Function for load image
 * @param {string} src
 * @return {Promise}
 */
const loadImage = (src) => new Promise((resolve, reject) => {
  const img = new dom.window.Image();

  img.src = src;
  img.onload = () => {
    resolve(img);
  };
  img.onerror = reject;
});

export default loadImage;
