import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';

import { findDevice } from '../../../helpers/browser';
import dom from '../../../wrapper/DomWrapper';
import defaultImage from '../utils';

/**
 *
 * @param {HTMLElement} elImage
 * @param {HTMLElement} elParent
 * @param {function=} cb
 */
const pictureErrorHandler = (elImage, elParent, cb) => {
  if (!elImage || !elParent) return;

  try {
    const sizes = JSON.parse(elImage.closest('picture')?.dataset?.sizes || {});

    if (!sizes) return;

    const deviceType = findDevice(dom.window.innerWidth, sizes);
    const source = dom.getElement(`source[data-device=${deviceType}]`, elParent);
    const previous = isNil(source) ? get(elImage, ['previousSibling', 'previousSibling']) : source;

    if (!previous) return;

    previous.srcset = defaultImage;

    if (isFunction(cb)) cb();
  } catch (error) {
    console.error(error);
  }
};

export default pictureErrorHandler;
